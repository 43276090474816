// eslint-disable-next-line import/no-extraneous-dependencies
import React from 'react';

import Skeleton from '@material-ui/lab/Skeleton';
import stylesMaterial from './stylesMaterial';

import StorefrontProduct from '../storefront-product';
import {
  Container,
  LongLine,
  StorefrontProductList,
  SegmentHeader,
  SegmentTitle,
  ShortLine,
  SeeAll,
} from './styles';
import { IProduto } from '../../interfaces/produto';
import { IGrupoProduto } from '../../interfaces/grupoProduto';

interface ProductListProps {
  grupo_title?: string;
  group?: IGrupoProduto;
  isLoading?: boolean;
  handleProduct?: (product: IProduto, type: number) => void;
  id: string;
}

const Storefront = ({
  group,
  grupo_title,
  isLoading,
  handleProduct,
  id
}: ProductListProps) => {
  const classes = stylesMaterial();

  return (
    <Container id={id}>
      {
        isLoading ? (
          <>
            <SegmentHeader>
              <SegmentTitle>
                <ShortLine />
                <Skeleton variant="rect" width={120} />
                <ShortLine />
              </SegmentTitle>

              <SeeAll>
                <Skeleton variant="rect" width={100} />
              </SeeAll>
            </SegmentHeader>
            <StorefrontProductList>
              <LongLine className={classes.linedivider2} />
              <StorefrontProduct handleProduct={() => null} isLoading={isLoading} />
              <LongLine className={classes.linedivider2} />
              <StorefrontProduct handleProduct={() => null} isLoading={isLoading} />
              <LongLine className={classes.linedivider2} />
              <StorefrontProduct handleProduct={() => null} isLoading={isLoading} />
            </StorefrontProductList>
          </>
        ) : (
          <>
            <SegmentHeader>
              <SegmentTitle>
                <ShortLine />
                <div className="title">{grupo_title?.toUpperCase()}</div>
                <ShortLine />
              </SegmentTitle>

            </SegmentHeader>
            <StorefrontProductList>
              {group &&
                group.produtos.map((produto, index) => {
                  return (
                    <React.Fragment key={produto.id}>
                      <LongLine className={classes.linedivider2} />
                      <StorefrontProduct
                        product={produto}
                        handleProduct={handleProduct}
                        isLoading={isLoading !== undefined && isLoading}
                      />
                      {group.produtos.length === index + 1 && (
                        <LongLine className={classes.linedivider2} />
                      )}
                    </React.Fragment>
                  );
                })}
            </StorefrontProductList>
          </>
        )
      }
    </Container>
  );
};

export default Storefront;
