import styled from 'styled-components';

export const TitleInfo = styled.h3`
  font-size: 16px;
  font-weight: bold;
  padding-left: 15px;
`

export const DescriptionInfo = styled.p`
  width: 80%;
  margin: 8px 0px 10px 15px !important;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.2rem;
`;

export const PriceInfo = styled.p`
  color: ${props => props.theme.primary};
  font-size: 18px;
  font-weight: bold;
`

export const Title = styled.h2`
  font-size: 16px;
  margin: 10px 0px 10px 35px;
`

export const ItemsTitle = styled.p`
  font-size: 14px;
`
export const InputObservation = styled.textarea`
  height: 100px;
  width: 100%;
  border: 1px solid gray;
  border-radius: 15px;
  padding: 10px 15px 5px;
  min-height: 100px;
    margin-bottom: 20px;
  ::placeholder {
    text-align: start;
  }
`

export const ButtonAdd = styled.button`
  width: 80%;
  background-color: ${props => props.theme.primary};
  color: #fff;
  height: 40px;
  border-radius: 8px;
  border: none;
  align-self: center;
  margin: 15px 0px;
  min-height: 40px;
  :hover {
    opacity: 0.7;
  }
  :active {
    background-color: #052b65;
  }
`

export const Container = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  margin-top: 7vh;
  margin-left: calc(calc(100vw - 550px) / 2);
  width: 550px;
  height: 80vh;
  border: '2px solid #000';
  align-self: center;
  background-color: #fefefe;
  border-radius: 0.3rem;
  @media(max-width: 960px){
    border-radius: 0;
    width: 100vw;
    height: 100vh;
    margin-top: 0;
    margin-left: 0;
  }
`;

export const Wrapper = styled.div`
  flex-grow: 1;
  padding-right: 1px;
  padding-top: 1px;
  max-height: 88%;
  @media(min-width: 700px){
    max-height: 80%;
  }
`;

export const Scroll = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  border-radius: 0.3rem 0.3rem 0 0;
  @media(max-width: 960px){
    height: calc(100vh - 90px);
  }
`;

export const Header = styled.div`
  display: -webkit-flex;
  flex-direction: row;
  width: 100%;
  min-height: max-content;
  div {
    display: -webkit-flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
`;

export const Body = styled.div.attrs({
  className: 'conteudo',
})`
  display: -webkit-flex;
  flex-direction: column;
  width: 100%;
  min-height: max-content;
  padding-right: 15px;
  padding-left: 15px;
  overflow-y: auto;
`;

export const Imagem = styled.div<{ defaultImg: string, image: string }>`
  background-image: url(defaultImg);
  display: -webkit-flex;
  align-items: center;
  justify-content: center;

  /* margin-top: 12px; */

  margin: 10px 0px 20px;

  div{
    height: 192px;
    width: 100%;
    border-radius: 0.5rem;

    background-image: url(${props => props.image}), url(${process.env.REACT_APP_RETAGUARDA_API_FILES}no_image.png);
    background-position: center;
    background-size: cover;
  }

  &:hover{
    cursor: zoom-in
  }

  @media(min-width: 700px){
    display: none;
  }

  @media(max-width: 960px){
    margin-top: 10px;
  }
`;

export const Quantidade = styled.div`
  display: -webkit-flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;

  min-height: max-content;
`;

export const Footer = styled.div`
  display: -webkit-flex;
  flex-direction: row;
  width: 100%;
  height: 90px;
  justify-content: center;
  align-items: center;
  border-radius:  0 0 0.3rem 0.3rem;
  background-color: #fefefe;
  margin-top: 10px;
  padding: 15px 0px;
`;

export const FigureImg = styled.figure<{ urlImage: string }>`
  background-image: url(${(props) => props.urlImage});
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  object-fit: cover;
  align-self: center;
  height: 100%;
  position: relative;
  width: 50%;
  min-width: 300px;
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  display: none;

  @media(min-width: 700px){
    display: inline;
  }
`;

export const ItemImage = styled.div<{ urlImage: string }>`
  background-image: url(${(props) => props.urlImage});
  background-position: center;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px 0px 0px 10px;
  background-size: cover;
`;

export const CarouselContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  @media (min-width: 700px){
    display: none;
  }
`;
