import React, { useContext, useEffect, useState } from 'react';

import _ from 'lodash';

import { Box, Button, Divider, IconButton, Typography, Link } from '@material-ui/core';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import { toast } from 'react-toastify';

import { IProduto } from '../../../interfaces/produto';
import { IComplementoCarrinho } from '../../../interfaces/complementoCarrinho';
import { IGrupoComplemento } from '../../../interfaces/grupoComplemento';
import { IProdutoGrade } from '../../../interfaces/produtoGrade';
import { IItemCarrinho } from '../../../interfaces/itemCarrinho';
import { ICor } from '../../../interfaces/cor';

import { calcOrderPrice } from '../../../utils/calculator';

import {
  Container,
  CarouselContainer,
  PriceContainer,
  QuantityContainer,
  Wrapper,
  Footer,
  Scroll,
  Header,
  Body,
  CarouselMobile
} from './styles';
import DefaultImage from '../../../assets/no_image.png';

import { Context } from '../../../hooks/cartStore';
import { useTheme } from '../../../hooks/useThemeState';
import stylesMaterial from './stylesMaterial';
import { useHistory } from 'react-router-dom';
import ProductComponentGroup from '../../../new-components/clothe-details-modal/components/ProductComponentGroup';
import { ProductCarousel } from '../../../new-components/product-carousel-food';
import produtosApi from '../../../services/produtosApi';
import { Carousel } from 'react-responsive-carousel';
import { SizeGroup } from './SizeGroup';

import { ColorGroup } from './ColorGroup';
import { Shipping } from '../../../services/pedidoApi';
import { ConsultShippingModal } from '../../../new-components/consult-shipping-modal';

export interface SizeForShow {
  size: string;
  colors: ICor[];
}

export interface ColorForShow {
  color: ICor;
  sizes: string[];
}

interface ClotheProductProps {
  product: IProduto;
  isStoreOpen: boolean;
  companyId: number;
  onClose: () => void;
  hasMelhorEnvio: boolean;
}

export function ClotheProduct({ product, isStoreOpen, companyId, onClose, hasMelhorEnvio }: ClotheProductProps) {
  const classes = stylesMaterial();
  const history = useHistory();
  const { state, dispatch } = useContext(Context);
  const [productWithComplements, setProductWithComplements] = useState<IProduto>(product)

  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState(0);
  const [observacao, setObservacao] = useState('');
  const [complementos, setComplementos] = useState(new Array<IComplementoCarrinho>());
  const [groupsUnderMinimum, setGroupsUnderMinimum] = useState<{id: number, under: boolean}[]>([]);
  const [anyGroupUnder, setAnyGroupUnder] = useState(false);

  const [colors, setColors] = useState<ColorForShow[]>([]);
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedColor, setSelectedColor] = useState<ColorForShow | null>(null);
  const [selectedProdutoGrade, setselectedProdutoGrade] = useState<IProdutoGrade | null>(
    product.produto_grades.find(grade => grade.destaque) || null
  );
  const [openConsultShippingModal, setOpenConsultShippingModal] = useState(false);
  const [selectedShipping, setSelectedShipping] = useState<Shipping | null>(null);

  const { theme } = useTheme()
  let id=0;
  const toast_config = {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  useEffect(() => {
    if (productWithComplements !== null) {
      let tempColors = productWithComplements.produto_grades.map((produtoGrade) => produtoGrade.cor);

      tempColors = tempColors.filter((color, index) => {
        return tempColors.map(tempColor => tempColor.id).indexOf(color.id) === index;
      });

      const tempColorsWithSizes = tempColors.map((color) => {
        return {
          color,
          sizes: product.produto_grades
            .filter((produtoGrade) => produtoGrade.cor.id === color.id)
            .map((produtoGrade) => produtoGrade.tamanho.descricao)
        }
      });
      setColors(tempColorsWithSizes);

      const productFeatured = productWithComplements.produto_grades.find(grid => grid.destaque)

      if (productFeatured) {
        const tempDefaultColor = tempColorsWithSizes.find((cor: any) => cor.color.id === productFeatured.cor.id);
        if(tempDefaultColor){
          setSelectedColor(tempDefaultColor);
        }
        setSelectedSize(productFeatured.tamanho.descricao);

      } else  {
        productWithComplements.produto_grades.forEach((produtoGrade) => {
          if(product.minGradePreco?.produto_grade_id === produtoGrade.id){
            const tempDefaultColor = tempColorsWithSizes.find((cor: any) => cor.color.id === produtoGrade.cor.id);
            if(tempDefaultColor){
              setSelectedColor(tempDefaultColor);
            }
            setSelectedSize(produtoGrade.tamanho.descricao);
          }
        });
      }
    }
  }, [productWithComplements]);

  useEffect(() => {
    if (productWithComplements && selectedProdutoGrade) {
      setPrice(
        calcOrderPrice({
          id: productWithComplements.id,
          prodComposto: {
            produto: productWithComplements,
            complementos,
          },
          produtosGrade: [],
          produtoGrade: selectedProdutoGrade,
          tamanho: null,
          quantidade: quantity,
          observacao,
        }),
      );
    }
  }, [quantity, complementos, productWithComplements, selectedProdutoGrade]);

  useEffect(() => {
    if (productWithComplements !== null) {
      productWithComplements.complemento.map((comp) =>{
        if (groupsUnderMinimum !== undefined){
          let tempGroupsUnder = groupsUnderMinimum;
          if ((_.toNumber(comp.qtd_min) > 0) && (_.toNumber(comp.qtd_max) > 1)){
            tempGroupsUnder.push({id: comp.id, under: true});
          } else{
            tempGroupsUnder.push({id: comp.id, under: false});
          }
          setGroupsUnderMinimum(tempGroupsUnder);
        } else{
          if ((_.toNumber(comp.qtd_min) > 0) && (_.toNumber(comp.qtd_max) > 1)){
            setGroupsUnderMinimum([{id: comp.id, under: true}]);
          } else{
            setGroupsUnderMinimum([{id: comp.id, under: false}]);
          }
        }
      });
    }
  }, [productWithComplements]);

  useEffect(() => {
    let counter = 0;

    if (groupsUnderMinimum!==undefined){
      groupsUnderMinimum.map((group) => {
        if (group.under){
          setAnyGroupUnder(true)
        } else{
          counter+=1;
        }
      });
      if (counter === groupsUnderMinimum.length){
        setAnyGroupUnder(false);
      }
    }
  }, [groupsUnderMinimum]);

  useEffect(() => {
    if(productWithComplements && selectedColor){
      const tempProdutoGrade = productWithComplements.produto_grades.find((produtoGrade) => {
        return (
          produtoGrade.tamanho.descricao === selectedSize
          &&
          produtoGrade.cor.id === selectedColor?.color.id
        );
      });

      if(tempProdutoGrade){
        setselectedProdutoGrade(tempProdutoGrade);
      }
    }
  }, [productWithComplements, selectedSize, selectedColor]);

  const setComponents = (cartComponent: IComplementoCarrinho) => {
    let temp: IComplementoCarrinho[];
    if(productWithComplements !== null){
      const grupo = productWithComplements.complemento.find((group) =>
        cartComponent.complemento.complemento_id === group.id
      );
      if (grupo !== undefined){
        if (_.toNumber(grupo.qtd_max) === 1){
          temp = complementos.filter((item: IComplementoCarrinho) => {
          return ((item.complemento.id !== cartComponent.complemento.id)
                    &&
                  (item.complemento.complemento_id !== cartComponent.complemento.complemento_id));
          });
        } else {
          temp = complementos.filter((item: IComplementoCarrinho) => {
            return item.complemento.id !== cartComponent.complemento.id;
          });
        }

        if (cartComponent.quantidade>0){
          temp.push(cartComponent);
        }
        setComplementos(temp);
      }
    }
  }

  const handleGroupsUnderMinimum = (groups: {id: number; under: boolean;}[]) => {
    setGroupsUnderMinimum(groups);
  }

  const addToCarts = () => {
    if (!isStoreOpen) {
      toast.error('Ops, no momento estamos fechados!', toast_config);
      return;
    }

    if (anyGroupUnder) {
      const firstGroupUnder = groupsUnderMinimum.find(group => group.under);

      if(firstGroupUnder) {
        const groupElement = document.getElementById(`Grupo-Complemento-${firstGroupUnder.id}`);

        if (groupElement) {
          groupElement.scrollIntoView({ behavior: 'smooth' });
          toast.error('Ops, selecione seu complemento', toast_config);
        }
      }
      return;
    }

    let cart = state.carts.find((cart) => cart.id===companyId);

    if (cart){
      addItemToCart(cart.cart);
    } else{
      dispatch({
        type: 'ADD_CART',
        empresaId: companyId,
        payload: [{
          id,
          prodComposto: {
            produto: productWithComplements,
            complementos
          },
          produtosGrade: [],
          produtoGrade: selectedProdutoGrade,
          tamanho: null,
          quantidade: quantity,
          observacao
        }]
      });
    }
    onClose();
  }

  const addItemToCart = (cart: IItemCarrinho[]) => {
    if(cart.length){
      id = cart[cart.length - 1].id + 1;
    }

    dispatch({
      type: 'ADD_ITEM',
      empresaId: companyId,
      payload: {
        id,
        prodComposto: {
          produto: productWithComplements,
          complementos
        },
        produtosGrade: [],
        produtoGrade: selectedProdutoGrade,
        tamanho: null,
        quantidade: quantity,
        observacao
      }
    });
  }

  const getImage = (selectedProdutoGrade: IProdutoGrade | null): string[] => {
    if (selectedProdutoGrade) {
      const imagens = selectedProdutoGrade?.imagens.map((imagem) => imagem.imagem) || []

      if (imagens.length > 0) {
        return imagens
      }
      return [product.imagem]
    }

    return []
  }

  function handleOpenConsultShippingModal() {
    setOpenConsultShippingModal(true);
  }

  function handleCloseConsultShippingModal() {
    setOpenConsultShippingModal(false);
  }

  return (
    productWithComplements === null ? (
      <div/>
    ) : (
    <Container>
      <CarouselContainer>
        <ProductCarousel
          images={getImage(selectedProdutoGrade)}
        />
      </CarouselContainer>

      <Box className={classes.modalContainerInfo}>
        <Wrapper>
          <Scroll>
            <Header>
              <IconButton
                aria-label="arrow-down"
                onClick={onClose}
                className={classes.buttonArrow}
              >
                <ArrowBackIosIcon
                  fontSize="large"
                  style={{ color: '#B22222' }}
                  className={classes.iconArrow}
                />
              </IconButton>
              <div>
                <Typography
                  variant="button"
                  className={classes.detailTitle}
                >
                  Detalhes
                </Typography>
              </div>
              <IconButton
                aria-label="close"
                onClick={onClose}
                className={classes.buttonClose}
              >
                <CloseIcon
                  style={{ color: '#B22222' }}
                  className={classes.iconClose}
                />
              </IconButton>
            </Header>
            <Divider />

            <Body>
              <CarouselMobile>
                <ProductCarousel
                  images={getImage(selectedProdutoGrade)}
                />
              </CarouselMobile>
              <Typography className={classes.clotheTitle}>
                {productWithComplements.descricao}
              </Typography>
              <div>
                <Typography variant="body1" className={classes.productDesc}>
                  {productWithComplements.observacao}
                </Typography>
                <PriceContainer>
                  {productWithComplements.produto_promocao &&
                    productWithComplements.produto_promocao.filter(
                      (produto_promocao) => produto_promocao.produto !== null,
                    ).length > 0 && (
                      <MotorcycleIcon
                        titleAccess={productWithComplements.produto_promocao
                          .filter(
                            (produto_promocao) =>
                              produto_promocao.produto !== null,
                          )
                          .map((produto_promocao, index) => {
                            let text = '';

                            if (index !== 0) {
                              text += '\n\n';
                            }

                            text += `${produto_promocao.promocao?.nome}\n`;
                            text += produto_promocao.promocao?.descricao;

                            return text;
                          })
                          .reduce((prev, next) => prev.concat(next))}
                        style={{ fontSize: 28 }}
                      />
                    )}
                  <Typography
                    className={
                      selectedProdutoGrade?.promocao?.ativo
                        ? classes.oldPrice
                        : classes.price
                    }
                    style={
                      selectedProdutoGrade?.promocao?.ativo
                        ? {}
                        : { color: theme.primary }
                    }
                  >
                    R$ {
                      Number(selectedProdutoGrade?.promocao
                        ? (price / (1 - selectedProdutoGrade?.promocao.fator))
                        : price).toFixed(2).replace('.',',')
                    }
                  </Typography>
                  {
                    selectedProdutoGrade?.promocao?.ativo && (
                      <>
                        <Typography
                          className={classes.price}
                          style={{ color: theme.primary }}
                        >
                          R$ {Number(price).toFixed(2).replace('.',',')}
                        </Typography>
                        <Typography className={classes.discount}>
                          -{Math.round(selectedProdutoGrade.promocao.fator*100).toFixed(0)}%
                        </Typography>
                      </>
                    )
                  }
                  {selectedShipping && (
                    <Typography className={classes.shipping}>
                      {selectedShipping.name}:  +R$ {selectedShipping.price.replace('.', ',')}
                    </Typography>
                  )}
                </PriceContainer>
              </div>

              {selectedProdutoGrade?.estoque &&
                selectedProdutoGrade.estoque.ativo &&
                selectedProdutoGrade.estoque.estoque_atual == 0 && (
                  <Typography
                    className={classes.price}
                    style={{ color: theme.primary }}
                  >
                    Sem estoque
                  </Typography>
                )}

              <Divider className={classes.divider} />
              <div style={{ padding: '8px 0px 0px 20px' }} >
                <Typography className={classes.cores}>Cor - {selectedColor?.color.descricao}</Typography>
                <ColorGroup
                  colors={colors}
                  selectedColor={selectedColor}
                  setSelectedColor={setSelectedColor}
                  setSelectedSize={setSelectedSize}
                />
              </div>
              <Divider className={classes.divider} />
              <div style={{ padding: '8px 0px 0px 20px' }} >
                <Typography className={classes.cores}>Tamanho</Typography>
                <SizeGroup
                  sizes={selectedColor ? selectedColor.sizes : []}
                  selectedSize={selectedSize}
                  setSelectedSize={setSelectedSize}
                />
              </div>
              <Divider className={classes.divider} />

              {
                productWithComplements.complemento.map((grupoComplemento: IGrupoComplemento) => {
                  return (
                    <ProductComponentGroup
                    grupoComplemento={grupoComplemento}
                    setComponent={setComponents}
                    complementos={complementos}
                    isLoading={false}
                    groupsUnderMinimum={groupsUnderMinimum}
                    handleGroupsUnderMinimum={handleGroupsUnderMinimum}
                    key={grupoComplemento.id}
                    />
                    );
                  })
              }

              {hasMelhorEnvio && (
                <Link
                  component="button"
                  onClick={handleOpenConsultShippingModal}
                  className={classes.consultShipping}
                >
                  Consultar frete
                </Link>
              )}
            </Body>
          </Scroll>
        </Wrapper>


        <Footer>
          <QuantityContainer>
            <IconButton
              color="primary"
              aria-label="remover
              item"
              component="span"
              disabled={quantity===1}
              onClick={() => setQuantity(quantity - 1)}
              style={{ color: theme.primary }}
              >
              <RemoveCircleIcon />
            </IconButton>
            <Typography className={classes.quantity}>{quantity}</Typography>
            <IconButton
              color="primary"
              aria-label="adicionar item"
              component="span"
              disabled={
                selectedProdutoGrade?.estoque &&
                selectedProdutoGrade.estoque.ativo &&
                quantity == selectedProdutoGrade.estoque.estoque_atual
              }
              onClick={() => setQuantity(quantity + 1)}
              style={{ color: theme.primary }}
              >
              <AddCircleIcon />
            </IconButton>
          </QuantityContainer>
          
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={addToCarts}
            disabled={
              (
                selectedProdutoGrade?.estoque &&
                selectedProdutoGrade.estoque.ativo &&
                Number(selectedProdutoGrade.estoque.estoque_atual) === 0
              )
            }
            style={{
              color: '#fefefe',
              height: '45px',
              width: '90%',
              backgroundColor:
                (
                  selectedProdutoGrade?.estoque &&
                  selectedProdutoGrade.estoque.ativo &&
                  Number(selectedProdutoGrade.estoque.estoque_atual) === 0
                )
                  ? '#757575'
                  : theme.primary,
            }}
            >
            Adicionar R$ {
              Number(price)
                .toFixed(2)
                .replace(".", ",")
              }
          </Button>
        </Footer>

        {openConsultShippingModal && selectedProdutoGrade && (
          <ConsultShippingModal 
            openConsultShippingModal={openConsultShippingModal}
            handleCloseConsultShippingModal={handleCloseConsultShippingModal}
            gridId={selectedProdutoGrade.id}
            quantity={quantity}
            companyId={companyId}
            selectedShipping={selectedShipping}
            setSelectedShipping={setSelectedShipping}
          />
        )}
      </Box>
    </Container>
    )
  );
}
