import React, { useState } from 'react';

import Typography from '@material-ui/core/Typography';

import ScrollContainer from 'react-indiana-drag-scroll';
import { ClothingCard } from '../clothing-card';

import { Cards, Container, LineTitle, LinkText, TitleGroup } from './styles';
import stylesMaterial from './stylesMaterial';

import { IGrupoProduto } from '../../interfaces/grupoProduto';
import { IEmpresa } from '../../interfaces/empresa';
import { useTheme } from '../../hooks/useThemeState';
import { ISchedule } from '../../interfaces/schedule';

interface ClothingGroupProps {
  group: IGrupoProduto;
  company: IEmpresa;
  productIdByBanner: number | null;
  setNullProductIdByBanner: () => void;
  scheduledDays: ISchedule | null;
}

export const ClothingGroup: React.FC<ClothingGroupProps> = (props) => {
  const [showAll, setShowAll] = useState(false);
  const { theme } = useTheme();
  const classes = stylesMaterial();

  const {
    group,
    company,
    productIdByBanner,
    setNullProductIdByBanner,
    scheduledDays,
  } = props;

  return (
    <Container id={`Grupo-${group.id}`}>
      <TitleGroup>
        <LineTitle />
        <Typography className={classes.groupTitle}>
          {group.descricao}
        </Typography>
        <LineTitle />
      </TitleGroup>
      <LinkText
        showAll={showAll}
        href="/#"
        onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
          event.preventDefault();
          setShowAll(!showAll);
        }}
        style={{
          color: theme.primary,
          borderBottom: `1px solid ${theme.primary}`,
        }}
      >
        {showAll ? 'Esconder' : 'Ver todos'}
      </LinkText>
      {showAll ? (
        <Cards>
          {group.produtos.map((produto) => (
            <ClothingCard
              key={produto.id}
              product={produto}
              company={company}
              group={group}
              productIdByBanner={productIdByBanner}
              setNullProductIdByBanner={setNullProductIdByBanner}
              scheduledDays={scheduledDays}
            />
          ))}
        </Cards>
      ) : (
        <ScrollContainer
          horizontal
          hideScrollbars
          nativeMobileScroll
          className={classes.carouselRoot}
        >
          {group.produtos.map((produto) => {
            return (
              <ClothingCard
                key={produto.id}
                product={produto}
                company={company}
                group={group}
                productIdByBanner={productIdByBanner}
                setNullProductIdByBanner={setNullProductIdByBanner}
                scheduledDays={scheduledDays}
              />
            );
          })}
        </ScrollContainer>
      )}
    </Container>
  );
};
