import { Typography } from '@material-ui/core';
import React from 'react';
import Truncate from 'react-truncate';

import { IProduto } from '../../interfaces/produto';
import { IProdutoGrade } from '../../interfaces/produtoGrade';

import {
  DetailHeader,
  ItemDescription,
  ItemDetail,
  ItemName,
  ItemPrice,
  Price,
} from './styles';
import stylesMaterial from './stylesMaterial';

interface CustomDetailsProps {
  product: IProduto;
  featuredProduct?: IProdutoGrade;
}

export const CustomDetails: React.FC<CustomDetailsProps> = (props) => {
  const { product, featuredProduct } = props;

  const classes = stylesMaterial();

  return (
    <ItemDetail>
      <DetailHeader>
        <ItemName>
          {product.vitrine 
            ? `${product.descricao} ${product.produto_grades[0].descricao}`
            : product.descricao
          }
        </ItemName>

        {product.fracionado && (
          <Typography className={classes.infoPortion}>
            {`Porção de ${
              product.fracao && (
                product.fracao >=1
                  ? product.fracao
                  : product.unidadeMedida.quantidadePorcao * product.fracao
              )
            }${
              product.fracao && product.fracao >=1 
                ? product.unidadeMedida.sigla
                : product.unidadeMedida.siglaPorcao
            }`}
          </Typography>
        )}
      </DetailHeader>

      <ItemDescription>
        <Truncate lines={3} ellipsis={<span>...</span>} trimWhitespace>
          {product.observacao}
        </Truncate>
      </ItemDescription>

      {featuredProduct ? (
        <Price simpleType={product.controla_grade === 0}>
          {featuredProduct.promocao && featuredProduct.promocao.ativo && (
            <Typography className={classes.oldPrice}>
              R${' '}
              {Number(Number(featuredProduct.preco.valor))
                .toFixed(2)
                .replace('.', ',')}
            </Typography>
          )}
          <ItemPrice>
            R${' '}
            {Number(
              featuredProduct.promocao
                ? Number(featuredProduct.preco.valor) *
                    (1 - featuredProduct.promocao.fator)
                : Number(featuredProduct.preco.valor),
            )
              .toFixed(2)
              .replace('.', ',')}
          </ItemPrice>
        </Price>
      ) : (
        <Price simpleType={product.controla_grade === 0}>
          {product.controla_grade === 0 // padrão
            ? product.promocao &&
              product.promocao.ativo && (
                <Typography className={classes.oldPrice}>
                  R$ {Number(product.preco.valor *
                    (product.fracionado && product.fracao
                      ? product.fracao
                      : 1)
                  ).toFixed(2).replace('.', ',')}
                </Typography>
              )
            : // grade e pizza
              product.minGradePromocao &&
              product.minGradePromocao.ativo && (
                <Typography className={classes.oldPrice}>
                  R${' '}
                  {Number(product.minGradePreco?.valor)
                    .toFixed(2)
                    .replace('.', ',')}
                </Typography>
              )}
          <ItemPrice>
            {product.controla_grade === 0
              ? `R$ ${(Math.ceil(Number(
                  product.promocao?.ativo
                    ? Number(product.preco?.valor  *
                      (product.fracionado && product.fracao
                        ? product.fracao
                        : 1)) *
                        (1 - product.promocao.fator)
                    : Number(product.preco?.valor *
                      (product.fracionado && product.fracao
                        ? product.fracao
                        : 1)),
                ) * 100) / 100)
                  .toFixed(2)
                  .replace('.', ',')}`
              : `${product.controla_grade === 2 ? 'A partir de R$ ' : 'R$ '} ` +
                (
                  Math.round(
                    (product?.minGradePreco
                      ? product.minGradePromocao &&
                        product.minGradePromocao.ativo
                        ? product.minGradePreco?.valor *
                          (1 - product.minGradePromocao.fator)
                        : product.minGradePreco?.valor
                      : 0) * 100,
                  ) / 100
                )
                  .toFixed(2)
                  .replace('.', ',')}
          </ItemPrice>
        </Price>
      )}
    </ItemDetail>
  );
};
